import {defineStore} from "pinia";
import {DetailedEstimation} from "@/properties/model/detailedEstimation";

export const estimationService = defineStore('estimationResult', {
    state: ():
        DetailedEstimation => ({
        ...estimationDefaultState
    }),

    actions: {
        updateCurrentEstimation(estimation: DetailedEstimation) {
            this.$state = estimation;
        },
        reset() {
            this.$reset();
        },
    },
    getters: {
        getTotalEstimation(state) {
            return state.totalEstimation;
        },
        getWallEstimations(state) {
            return state.wallEstimations;
        },
        getFloorEstimation(state) {
            return state.floorEstimation;
        },
        getDoorEstimations(state) {
            return state.doorEstimations;
        },
        getOuterWindowEstimations(state) {
            return state.outerWindowEstimations;
        },
        getRoofWindowEstimations(state) {
            return state.roofWindowEstimations;
        },
        getRoofEstimations(state) {
            return state.roofEstimations;
        },
        getBasementEstimation(state) {
            return state.basementEstimation;
        },
        getMoreEstimation(state) {
            return state.moreEstimation;
        },
        getBuildingTechnologyEstimation(state) {
            return state.buildingTechnologyEstimation;
        }
    }
});

const estimationDefaultState: DetailedEstimation = {
    totalEstimation: {
        area: 0,
        errorHeatLoss: 0,
        heatLoss: 0,
        energyMetric: 0,
        energyMetricError: 0
    },
    wallEstimations: [{
        buildingPartKey: '',
        errorHeatLoss: 0,
        heatLoss: 0,
        area: 0,
        areaError: 0,
        uError: 0,
        uValue: 0,
        energyMetric: 0,
        energyMetricError: 0,
        bestUValue: 0,
        worstUValue: 0,
    }],
    floorEstimation: {
        errorHeatLoss: 0,
        heatLoss: 0,
        area: 0,
        areaError: 0,
        uError: 0,
        uValue: 0,
        energyMetric: 0,
        energyMetricError: 0,
        bestUValue: 0,
        worstUValue: 0,
    },
    doorEstimations: [{
        buildingPartKey: '',
        errorHeatLoss: 0,
        heatLoss: 0,
        area: 0,
        areaError: 0,
        uError: 0,
        uValue: 0,
        energyMetric: 0,
        energyMetricError: 0,
        bestUValue: 0,
        worstUValue: 0
    }],
    outerWindowEstimations: [{
        buildingPartKey: '',
        errorHeatLoss: 0,
        heatLoss: 0,
        area: 0,
        areaError: 0,
        uError: 0,
        uValue: 0,
        energyMetric: 0,
        energyMetricError: 0,
        bestUValue: 0,
        worstUValue: 0
    }],
    roofWindowEstimations: [{
        buildingPartKey: '',
        errorHeatLoss: 0,
        heatLoss: 0,
        area: 0,
        areaError: 0,
        uError: 0,
        uValue: 0,
        energyMetric: 0,
        energyMetricError: 0,
        bestUValue: 0,
        worstUValue: 0
    }],
    roofEstimations: [{
        buildingPartKey: '',
        errorHeatLoss: 0,
        heatLoss: 0,
        outerArea: 0,
        area: 0,
        areaError: 0,
        uError: 0,
        uValue: 0,
        energyMetric: 0,
        energyMetricError: 0,
        bestUValue: 0,
        worstUValue: 0
    }],
    basementEstimation: {
        errorHeatLoss: 0,
        heatLoss: 0,
        area: 0,
        areaError: 0,
        uError: 0,
        uValue: 0,
        energyMetric: 0,
        energyMetricError: 0,
        bestUValue: 0,
        worstUValue: 0
    },
    moreEstimation: {
        internalEnergyWin: 0,
        takenAssumption: 0,
        temperatureFactor: 0,
        totalAssumption: 0,
        usable_area: 0,
        volume: 0
    },
    buildingTechnologyEstimation: {
        heatingEffortNumber: 0,
        heatingEnergy: 0,
        heatingTank: 0,
        waterEffortNumber: 0,
        waterEnergy: 0,
        waterTank: 0,
        ventilationHeatLoss: 0,
        heatingSource: 0,
        waterSource: 0
    }
}