import { defineStore } from 'pinia'
import constants from '@/properties/views/components/PropertyRegistration/scripts/constants';
import {nextTick} from "vue";

// Store für Zustand der Detailerfassung
// Aktive Kategorie, Hilfepanel u.ä. werden über diesen Store gespeichert und für die Navigationsroutinen vorgehalten
const pageDefaultState = {
  currentPage: 1,
  currentResultsActive: false,
  helpActive: false,
  accessFlags: {
    intro: {
      show: true
    },
    basic: {
      show: true,
      editable: true
    },
    basicBasics: {
      show: true,
      editable: true
    },
    basicLocation: {
      show: true,
      editable: true
    },
    basicFloorPlan: {
      show: true,
      editable: true
    },
    basicAdditionals: {
      show: true,
      editable: true
    },
    consumption: {
      show: true,
      editable: true
    },
    location: {
      show: true,
      editable: true
    },
    basicImages: {
      show: true,
      editable: true
    },
    wall: {
      show: true,
      editable: true
    },
    floor: {
      show: true,
      editable: true
    },
    window: {
      show: true,
      editable: true
    },
    windowImages: {
      show: true,
      editable: true
    },
    roof: {
      show: true,
      editable: true
    },
    technology: {
      show: true,
      editable: true
    },
    technologyHeating: {
      show: true,
      editable: true
    },
    technologyWater: {
      show: true,
      editable: true
    },
    technologyVentilation: {
      show: true,
      editable: true
    },
    technologyPhotovoltaic: {
      show: true,
      editable: true
    },
    detailedResults: {
      show: true
    }
  },
  helpIndex: 0,
  windowDetailsType: null,
  windowDetailsIndex: null
}

export const pageService = defineStore('page', {
  state() {
    return {
      ...pageDefaultState
    }
  },
  actions: {
    reset() {
      this.$state = JSON.parse(JSON.stringify(pageDefaultState));
    },
    isEditable() {
      switch(this.currentPage) {
        case constants.AllBasics: return this.accessFlags.basic.editable;
        case constants.BasicsBasic: return this.accessFlags.basicBasics.editable;
        case constants.BasicsLocation: return this.accessFlags.basicLocation.editable;
        case constants.BasicsFloorPlan: return this.accessFlags.basicFloorPlan.editable;
        case constants.BasicsAdditionals: return this.accessFlags.basicAdditionals.editable;
        case constants.AllWalls: return this.accessFlags.wall.editable;
        case constants.AllFloor: return this.accessFlags.floor.editable;
        case constants.AllWindows: return this.accessFlags.window.editable;
        case constants.AllRoof: return this.accessFlags.roof.editable;
        case constants.AllTechnology: return this.accessFlags.technology.editable;
        case constants.TechnologyHeating: return this.accessFlags.technologyHeating.editable;
        case constants.TechnologyWater: return this.accessFlags.technologyWater.editable;
        case constants.TechnologyVentilation: return this.accessFlags.technologyVentilation.editable;
        case constants.TechnologyPhotovoltaic: return this.accessFlags.technologyPhotovoltaic.editable;
        default: return true;
      }
    },
    isShowable(pageIndex: number) {
      switch(pageIndex) {
        case constants.Intro: return this.accessFlags.intro.show;
        case constants.AllBasics: return this.accessFlags.basic.show;
        case constants.BasicsBasic: return this.accessFlags.basicBasics.show;
        case constants.BasicsLocation: return this.accessFlags.basicLocation.show;
        case constants.BasicsFloorPlan: return this.accessFlags.basicFloorPlan.show;
        case constants.BasicsAdditionals: return this.accessFlags.basicAdditionals.show;
        case constants.Consumption: return this.accessFlags.consumption.show;
        case constants.Location: return this.accessFlags.location.show;
        case constants.AllWalls: return this.accessFlags.wall.show;
        case constants.AllFloor: return this.accessFlags.floor.show;
        case constants.AllWindows: return this.accessFlags.window.show;
        case constants.AllRoof: return this.accessFlags.roof.show;
        case constants.AllTechnology: return this.accessFlags.technology.show;
        case constants.TechnologyHeating: return this.accessFlags.technologyHeating.show;
        case constants.TechnologyWater: return this.accessFlags.technologyWater.show;
        case constants.TechnologyVentilation: return this.accessFlags.technologyVentilation.show;
        case constants.TechnologyPhotovoltaic: return this.accessFlags.technologyPhotovoltaic.show;
        case constants.DetailedResults: return this.accessFlags.detailedResults.show;
        case constants.BasicImages: return this.accessFlags.basicImages.show;
        case constants.WindowImages: return this.accessFlags.windowImages.show;
        default: return true;
      }
    },
    //Helper-Funktion um den Bearbeitungsmodus zu de/aktivieren
    changeEditableState(pageIndex: number, state: boolean) {
      switch(pageIndex) {
        case constants.AllBasics: this.accessFlags.basic.editable = state; break;
        case constants.BasicsBasic: this.accessFlags.basicBasics.editable = state; break;
        case constants.BasicsLocation: this.accessFlags.basicLocation.editable = state; break;
        case constants.BasicsFloorPlan: this.accessFlags.basicFloorPlan.editable = state; break;
        case constants.BasicsAdditionals: this.accessFlags.basicAdditionals.editable = state; break;
        case constants.AllWalls: this.accessFlags.wall.editable = state; break;
        case constants.AllFloor: this.accessFlags.floor.editable = state; break;
        case constants.AllWindows: this.accessFlags.window.editable = state; break;
        case constants.AllRoof: this.accessFlags.roof.editable = state; break;
        case constants.AllTechnology: this.accessFlags.technology.editable = state; break;
        case constants.TechnologyHeating: this.accessFlags.technologyHeating.editable = state; break;
        case constants.TechnologyWater: this.accessFlags.technologyWater.editable = state; break;
        case constants.TechnologyVentilation: this.accessFlags.technologyVentilation.editable = state; break;
        case constants.TechnologyPhotovoltaic: this.accessFlags.technologyPhotovoltaic.editable = state; break;
      }
    },
    changeEntireEditableState(state: boolean) {
      this.accessFlags.basic.editable = state;
      this.accessFlags.basicBasics.editable = state;
      this.accessFlags.basicLocation.editable = state;
      this.accessFlags.basicFloorPlan.editable = state;
      this.accessFlags.basicAdditionals.editable = state;
      this.accessFlags.wall.editable = state;
      this.accessFlags.floor.editable = state;
      this.accessFlags.window.editable = state;
      this.accessFlags.roof.editable = state;
      this.accessFlags.technology.editable = state;
      this.accessFlags.technologyHeating.editable = state;
      this.accessFlags.technologyWater.editable = state;
      this.accessFlags.technologyVentilation.editable = state;
      this.accessFlags.technologyPhotovoltaic.editable = state;
    },
    //Helper-Funktion um den Anzeigemodus zu de/aktivieren
    changeShowState(pageIndex: number, state: boolean) {
      switch(pageIndex) {
        case constants.Intro: this.accessFlags.intro.show = state; break;
        case constants.AllBasics: this.accessFlags.basic.show = state; break;
        case constants.BasicsBasic: this.accessFlags.basicBasics.show = state; break;
        case constants.BasicsLocation: this.accessFlags.basicLocation.show = state; break;
        case constants.BasicsFloorPlan: this.accessFlags.basicFloorPlan.show = state; break;
        case constants.BasicsAdditionals: this.accessFlags.basicAdditionals.show = state; break;
        case constants.Consumption: this.accessFlags.consumption.show = state; break;
        case constants.Location: this.accessFlags.location.show = state; break;
        case constants.AllWalls: this.accessFlags.wall.show = state; break;
        case constants.AllFloor: this.accessFlags.floor.show = state; break;
        case constants.AllWindows: this.accessFlags.window.show = state; break;
        case constants.AllRoof: this.accessFlags.roof.show = state; break;
        case constants.AllTechnology: this.accessFlags.technology.show = state; break;
        case constants.TechnologyHeating: this.accessFlags.technologyHeating.show = state; break;
        case constants.TechnologyWater: this.accessFlags.technologyWater.show = state; break;
        case constants.TechnologyVentilation: this.accessFlags.technologyVentilation.show = state; break;
        case constants.TechnologyPhotovoltaic: this.accessFlags.technologyPhotovoltaic.show = state; break;
        case constants.DetailedResults: this.accessFlags.detailedResults.show = state; break;
        case constants.BasicImages: this.accessFlags.basicImages.show = state; break;
        case constants.WindowImages: this.accessFlags.windowImages.show = state; break;
      }
    },
    //Handling für Unterseiten-Wechsel und Scroll-Höhe
    changePage(page: number) {
      this.currentPage = page;

      // scroll property registration component to top
      nextTick(() => {
        const element = document.getElementById('mainOutlet');
        if (element) {
          element.scrollTop = 0;
        }
      });
      // scroll main component to top (incl. footer etc.)
      nextTick(() => {
        const element = document.getElementById('main');
        if (element) {
          element.scrollTop = 0;
        }
      });
    },
    //Handling für Hilfe-Panel und Scroll-Höhe
    toggleHelpActive(helpIndex: number) {
      if (helpIndex === 0 || this.helpIndex === helpIndex) {
        this.helpActive = false;
        this.helpIndex = 0;
        return false;
      }

      const element = document.getElementById('helpOutlet');
      if (element) {
        element.scrollTop = 0;
      }

      this.helpActive = true;
      this.helpIndex = helpIndex;
    },
    //Übersetzen der Page-Indexe in Button-Beschriftungen
    getName(page: number | null) {
      if(!page)
        return ''

      switch(page) {
        case constants.Intro: return 'Einleitung';
        case constants.OuterViews: return 'Außenansichten';
        case constants.BasicData: return 'Gebäudedaten';
        case constants.AllBasics: return 'Gebäudedaten';
        case constants.BasicsBasic: return 'Gebäude';
        case constants.BasicsLocation: return 'Standort';
        case constants.BasicsFloorPlan: return 'Pläne';
        case constants.BasicsAdditionals: return 'Fotos';
        case constants.AllWalls: return 'Außenwände';
        case constants.AllFloor: return 'Boden';
        case constants.AllWindows: return 'Fenster & Türen';
        case constants.AllRoof: return 'Dach';
        case constants.AllTechnology: return 'Gebäudetechnik';
        case constants.TechnologyHeating: return 'Heizung';
        case constants.TechnologyPhotovoltaic: return 'Photovoltaik';
        case constants.TechnologyVentilation: return 'Lüftung';
        case constants.TechnologyWater: return 'Warmwasser';
        case constants.DetailedResults: return 'Detailbewertung';
      }
    },
    firstPage(skipIntro?: boolean) {
      if(this.accessFlags.intro.show && typeof(skipIntro) === 'undefined') {
        return constants.Intro;
      } else if(this.accessFlags.basic.show) {
        return constants.AllBasics
      } else if(this.accessFlags.wall.show) {
        return constants.AllWalls
      } else if(this.accessFlags.floor.show) {
        return constants.AllFloor
      } else if(this.accessFlags.window.show) {
        return constants.AllWindows
      } else if(this.accessFlags.roof.show) {
        return constants.AllRoof
      } else if(this.accessFlags.technology.show) {
        return constants.AllTechnology
      } else {
        return constants.DetailedResults
      }
    },
    nextPage() {
      // handle image based registration separately
      if (this.currentPage === constants.OuterViews) {
        return constants.BasicData;
      }
      if (this.currentPage < constants.AllBasics && this.accessFlags.basic.show) {
        return constants.AllBasics;
      } else if (this.currentPage < constants.BasicsBasic && this.accessFlags.basic.show) {
        return constants.BasicsBasic;
      } else if (this.currentPage < constants.BasicsLocation && this.accessFlags.basicLocation.show) {
        return constants.BasicsLocation;
      } else if (this.currentPage < constants.BasicsFloorPlan && this.accessFlags.basicImages.show) {
        return constants.BasicsFloorPlan;
      } else if (this.currentPage < constants.BasicsAdditionals && this.accessFlags.basicImages.show) {
        return constants.BasicsAdditionals;
      } else if (this.currentPage < constants.AllWalls && this.accessFlags.wall.show) {
        return constants.AllWalls;
      } else if (this.currentPage < constants.AllFloor && this.accessFlags.floor.show) {
        return constants.AllFloor;
      } else if (this.currentPage < constants.AllWindows && this.accessFlags.window.show) {
        return constants.AllWindows;
      } else if (this.currentPage < constants.AllRoof && this.accessFlags.roof.show) {
        return constants.AllRoof;
      } else if (this.currentPage < constants.AllTechnology && this.accessFlags.technology.show) {
        return constants.AllTechnology;
      } else if (this.currentPage < constants.TechnologyHeating && this.accessFlags.technology.show) {
        return constants.TechnologyHeating;
      } else if (this.currentPage < constants.TechnologyWater && this.accessFlags.technology.show) {
        return constants.TechnologyWater;
      } else if (this.currentPage < constants.TechnologyVentilation && this.accessFlags.technology.show) {
        return constants.TechnologyVentilation;
      } else if (this.currentPage < constants.TechnologyPhotovoltaic && this.accessFlags.technology.show) {
        return constants.TechnologyPhotovoltaic;
      } else if (this.currentPage < constants.DetailedResults && this.accessFlags.detailedResults.show) {
        return constants.DetailedResults;
      } else {
        return null;
      }
    },
    previousPage() {
      // handle image based registration separately
      if (this.currentPage === constants.OuterViews) {
        return constants.Intro;
      }
      if (this.currentPage === constants.BasicData) {
        return constants.OuterViews;
      }
      if (this.currentPage > constants.DetailedResults && this.accessFlags.detailedResults) {
        return constants.DetailedResults;
      } else if (this.currentPage > constants.TechnologyPhotovoltaic && this.accessFlags.technology.show) {
        return constants.TechnologyPhotovoltaic;
      } else if (this.currentPage > constants.TechnologyVentilation && this.accessFlags.technology.show) {
        return constants.TechnologyVentilation;
      } else if (this.currentPage > constants.TechnologyWater && this.accessFlags.technology.show) {
        return constants.TechnologyWater;
      } else if (this.currentPage > constants.TechnologyHeating && this.accessFlags.technology.show) {
        return constants.TechnologyHeating;
      } else if (this.currentPage > constants.AllRoof && this.accessFlags.roof.show) {
        return constants.AllRoof;
      } else if (this.currentPage > constants.AllWindows && this.accessFlags.window.show) {
        return constants.AllWindows;
      } else if (this.currentPage > constants.AllFloor && this.accessFlags.floor.show) {
        return constants.AllFloor;
      } else if (this.currentPage > constants.AllWalls && this.accessFlags.wall.show) {
        return constants.AllWalls;
      } else if (this.currentPage > constants.BasicsAdditionals && this.accessFlags.basicImages.show) {
        return constants.BasicsAdditionals;
      } else if (this.currentPage > constants.BasicsFloorPlan && this.accessFlags.basicImages.show) {
        return constants.BasicsFloorPlan;
      } else if (this.currentPage > constants.BasicsLocation && this.accessFlags.basicLocation.show) {
        return constants.BasicsLocation;
      } else if (this.currentPage > constants.BasicsBasic && this.accessFlags.basic.show) {
        return constants.BasicsBasic;
      } else if (this.currentPage > constants.Intro && this.accessFlags.intro.show ) {
        return constants.Intro;
      } else {
        return null;
      }
    },
    handleNavigationBarPageChange(pageToChangeTo: number) {
      console.log('handleNavigationBarPageChange');
    }
  }
});