import DetailedProperty from "@/properties/model/detailedProperty";
import {estimationService} from "@/properties/views/components/PropertyRegistration/stores/estimationResultData";
import {DetailedEstimation} from "@/properties/model/detailedEstimation";
import {WallEstimation} from "@/properties/model/wallEstimation";
import {RoofEstimation} from "@/properties/model/roofEstimation";
import {FloorEstimation} from "@/properties/model/floorEstimation";
import {DoorEstimation} from "@/properties/model/doorEstimation";
import {BasementEstimation} from "@/properties/model/basementEstimation";
import {
    improvementSuggestionService
} from "@/properties/views/components/PropertyRegistration/stores/improvementSuggestionData";
import {CalculatedMeasure} from "@/properties/model/calculatedMeasure";

export function resetScrollPosition(): void {
    const mainElement = document.getElementById("mainOutlet");
    if (!mainElement) {
        return;
    }
    mainElement.scrollTo(0, 0);
}

export function constructFillerPropertyName(properties: DetailedProperty[] | null): string {
    if (!properties) {
        return 'Neue Immobilie 1';
    }
    const regex = /Neue Immobilie (\d+)/;

    // find properties with name "Neue Immobilie X"
    const usedNumbers = properties
        .map(property => {
            if (!property.basic) {
                return false;
            }
            const match = property.basic.description.match(regex);
            return match ? parseInt(match[1], 10) : null;
        })
        .filter((num): num is number => num !== null)
        .sort((a, b) => a - b);

    // find smallest number
    let expectedNumber = 1;
    for (const num of usedNumbers) {
        if (num !== expectedNumber) {
            break;
        }
        expectedNumber++;
    }
    return 'Neue Immobilie ' + expectedNumber;
}

export function resolveImgURL(fileName: string) {
    if (fileName != undefined) {
        const images = require.context('@/properties/views/components/PropertyRegistration/assets/icons/', false, /\.svg$/);
        return images("./" + fileName);
    }
}

export function convertFloatToLocale(floatToConvert: number): string {
    return Intl.NumberFormat('de-DE').format(parseFloat(floatToConvert.toFixed(2)));
}

export function constructDetailedEstimation(): DetailedEstimation {
    const estimationStore = estimationService();
    const improvementSuggestionStore = improvementSuggestionService();

    const wallImprovement = improvementSuggestionStore.getWallImprovement;
    let efficientWallMeasureKey: {
        value: string
    };
    let efficientWallMeasure: CalculatedMeasure | undefined;
    const wallEstimations: WallEstimation[] = [];
    if (wallImprovement) {
        efficientWallMeasureKey = wallImprovement.efficientMeasureKey;
        efficientWallMeasure = wallImprovement.calculatedMeasures.find(measure => measure.measureKey.value === efficientWallMeasureKey.value);
        if (efficientWallMeasure) {
            for (let i = 0; i < estimationStore.wallEstimations.length; i++) {
                wallEstimations.push({
                    buildingPartKey: estimationStore.wallEstimations[i].buildingPartKey,
                    errorHeatLoss: estimationStore.wallEstimations[i].errorHeatLoss,
                    heatLoss: estimationStore.wallEstimations[i].heatLoss,
                    area: estimationStore.wallEstimations[i].area,
                    areaError: estimationStore.wallEstimations[i].areaError,
                    uError: estimationStore.wallEstimations[i].uError,
                    uValue: efficientWallMeasure.uValues[i],
                    energyMetric: estimationStore.wallEstimations[i].energyMetric,
                    energyMetricError: estimationStore.wallEstimations[i].energyMetricError,
                    bestUValue: estimationStore.wallEstimations[i].bestUValue,
                    worstUValue: estimationStore.wallEstimations[i].worstUValue
                });
            }
        }
    }

    let efficientRoofMeasureKey: {
        value: string
    };
    let efficientRoofMeasure: CalculatedMeasure | undefined;
    const roofEstimations: RoofEstimation[] = [];
    const roofImprovement = improvementSuggestionStore.getRoofImprovement;
    if (roofImprovement) {
        efficientRoofMeasureKey = roofImprovement.efficientMeasureKey;
        efficientRoofMeasure = roofImprovement.calculatedMeasures.find(measure => measure.measureKey.value === efficientRoofMeasureKey.value);
        if (efficientRoofMeasure) {
            for (let i = 0; i < estimationStore.roofEstimations.length; i++) {
                roofEstimations.push({
                    buildingPartKey: estimationStore.roofEstimations[i].buildingPartKey,
                    errorHeatLoss: estimationStore.roofEstimations[i].errorHeatLoss,
                    heatLoss: estimationStore.roofEstimations[i].heatLoss,
                    outerArea: estimationStore.roofEstimations[i].outerArea,
                    area: estimationStore.roofEstimations[i].area,
                    areaError: estimationStore.roofEstimations[i].areaError,
                    uError: estimationStore.roofEstimations[i].uError,
                    uValue: efficientRoofMeasure.uValues[i],
                    energyMetric: estimationStore.roofEstimations[i].energyMetric,
                    energyMetricError: estimationStore.roofEstimations[i].energyMetricError,
                    bestUValue: estimationStore.roofEstimations[i].bestUValue,
                    worstUValue: estimationStore.roofEstimations[i].worstUValue
                });
            }
        }
    }

    let efficientFloorMeasureKey: {
        value: string
    };
    let efficientFloorMeasure: CalculatedMeasure | undefined;
    let floorEstimation: FloorEstimation | null = null;
    const floorImprovement = improvementSuggestionStore.getFloorImprovement;
    if (floorImprovement) {
        efficientFloorMeasureKey = floorImprovement.efficientMeasureKey;
        efficientFloorMeasure = floorImprovement.calculatedMeasures.find(measure => measure.measureKey.value === efficientFloorMeasureKey.value);
        if (efficientFloorMeasure) {
            floorEstimation = {
                errorHeatLoss: estimationStore.floorEstimation.errorHeatLoss,
                heatLoss: estimationStore.floorEstimation.heatLoss,
                area: estimationStore.floorEstimation.area,
                areaError: estimationStore.floorEstimation.areaError,
                uError: estimationStore.floorEstimation.uError,
                uValue: efficientFloorMeasure.uValues[0],
                energyMetric: estimationStore.floorEstimation.energyMetric,
                energyMetricError: estimationStore.floorEstimation.energyMetricError,
                bestUValue: estimationStore.floorEstimation.bestUValue,
                worstUValue: estimationStore.floorEstimation.worstUValue
            };
        }
    }

    let efficientDoorMeasureKey: {
        value: string
    };
    let efficientDoorMeasure: CalculatedMeasure | undefined;
    const doorEstimations: DoorEstimation[] = [];
    const doorImprovement = improvementSuggestionStore.getDoorImprovement;
    if (doorImprovement) {
        efficientDoorMeasureKey = doorImprovement.efficientMeasureKey;
        efficientDoorMeasure = doorImprovement.calculatedMeasures.find(measure => measure.measureKey.value === efficientDoorMeasureKey.value);
        if (efficientDoorMeasure) {
            for (let i = 0; i < estimationStore.roofEstimations.length; i++) {
                doorEstimations.push({
                    buildingPartKey: estimationStore.doorEstimations[i].buildingPartKey,
                    errorHeatLoss: estimationStore.doorEstimations[i].errorHeatLoss,
                    heatLoss: estimationStore.doorEstimations[i].heatLoss,
                    area: estimationStore.doorEstimations[i].area,
                    areaError: estimationStore.doorEstimations[i].areaError,
                    uError: estimationStore.doorEstimations[i].uError,
                    uValue: efficientDoorMeasure.uValues[i],
                    energyMetric: estimationStore.doorEstimations[i].energyMetric,
                    energyMetricError: estimationStore.doorEstimations[i].energyMetricError,
                    bestUValue: estimationStore.doorEstimations[i].bestUValue,
                    worstUValue: estimationStore.doorEstimations[i].worstUValue
                });
            }
        }
    }

    let efficientBasementMeasureKey: {
        value: string
    };
    let efficientBasementMeasure: CalculatedMeasure | undefined;
    let basementEstimation: BasementEstimation | null = null;
    const basementImprovement = improvementSuggestionStore.getBasementImprovement;
    if (basementImprovement) {
        efficientBasementMeasureKey = basementImprovement.efficientMeasureKey;
        efficientBasementMeasure = basementImprovement.calculatedMeasures.find(measure => measure.measureKey.value === efficientBasementMeasureKey.value);
        if (efficientBasementMeasure) {
            basementEstimation = {
                errorHeatLoss: estimationStore.basementEstimation.errorHeatLoss,
                heatLoss: estimationStore.basementEstimation.heatLoss,
                area: estimationStore.basementEstimation.area,
                areaError: estimationStore.basementEstimation.areaError,
                uError: estimationStore.basementEstimation.uError,
                uValue: efficientBasementMeasure.uValues[0],
                energyMetric: estimationStore.basementEstimation.energyMetric,
                energyMetricError: estimationStore.basementEstimation.energyMetricError,
                bestUValue: estimationStore.basementEstimation.bestUValue,
                worstUValue: estimationStore.basementEstimation.worstUValue
            };
        }
    }

    return {
        totalEstimation: estimationStore.totalEstimation,
        wallEstimations: wallEstimations,
        floorEstimation: floorEstimation ?? estimationStore.floorEstimation,
        doorEstimations: doorEstimations,
        outerWindowEstimations: estimationStore.outerWindowEstimations,
        roofWindowEstimations: estimationStore.roofWindowEstimations,
        roofEstimations: roofEstimations,
        basementEstimation: basementEstimation ?? estimationStore.basementEstimation,
        moreEstimation: estimationStore.moreEstimation,
        buildingTechnologyEstimation: estimationStore.buildingTechnologyEstimation
    }
}
