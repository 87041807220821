import {defineStore} from "pinia";
import {DetailedEstimation} from "@/properties/model/detailedEstimation";
import {ImprovementSuggestion} from "@/properties/model/improvementSuggestion";

export const improvementSuggestionService = defineStore('improvementSuggestionResult', {

    state: (): {
        improvementSuggestions: ImprovementSuggestion[];
        newEstimation: DetailedEstimation | null;
        selectedImprovementSteps: number[];
    } => ({
        improvementSuggestions: [],
        newEstimation: null,
        selectedImprovementSteps: []
    }),
    actions: {
        updateCurrentSuggestion(improvementSuggestions: ImprovementSuggestion[]) {
            this.$state.improvementSuggestions = improvementSuggestions;
        },
        updateNewEstimation(newEstimation: DetailedEstimation | null) {
            this.$state.newEstimation = newEstimation;
        },
        updateSelectedImprovementSteps(category: number, step: number) {
            this.$state.selectedImprovementSteps[category] = step;
        },
        reset() {
            this.$reset();
        },
    },
    getters: {
        // getTotalHeatLossPerArea(state): number {
        //     return state.detailedEstimations[0].totalEstimation.heatLossPerBasicArea;
        // },
        // getTotalErrorHeatLossPerArea(state): number {
        //     return state.detailedEstimations[0].totalEstimation.errorHeatLossPerBasicArea;
        // },
        getSelectedImprovementSteps(): number[] {
            return this.selectedImprovementSteps;
        },
        getAtticImprovement(): ImprovementSuggestion | null {
            return this.improvementSuggestions.find(e => {
                if (!e) {
                    return false;
                }
                return e.improvementSuggestionCategory === 'ATTIC';
            } ) || null;
        },
        getRoofImprovement(): ImprovementSuggestion | null {
            return this.improvementSuggestions.find(e => {
                if (!e) {
                    return false;
                }
                return e.improvementSuggestionCategory === 'ROOF';
            } ) || null;
        },
        getWallImprovement(): ImprovementSuggestion | null {
            return this.improvementSuggestions.find(e => {
                if (!e) {
                    return false;
                }
                return e.improvementSuggestionCategory === 'WALL';
            } ) || null;
        },
        getWindowImprovement(): ImprovementSuggestion | null {
            return this.improvementSuggestions.find(e => {
                if (!e) {
                    return false;
                }
                return e.improvementSuggestionCategory === 'WINDOW';
            } ) || null;
        },
        getDoorImprovement(): ImprovementSuggestion | null {
            return this.improvementSuggestions.find(e => {
                if (!e) {
                    return false;
                }
                return e.improvementSuggestionCategory === 'DOOR';
            } ) || null;
        },
        getHeatingImprovement(): ImprovementSuggestion | null {
            return this.improvementSuggestions.find(e => {
                if (!e) {
                    return false;
                }
                return e.improvementSuggestionCategory === 'HEATING';
            } ) || null;
        },
        getWaterImprovement(): ImprovementSuggestion | null {
            return this.improvementSuggestions.find(e => {
                if (!e) {
                    return false;
                }
                return e.improvementSuggestionCategory === 'WATER';
            } ) || null;
        },
        getVentilationImprovement(): ImprovementSuggestion | null {
            return this.improvementSuggestions.find(e => {
                if (!e) {
                    return false;
                }
                return e.improvementSuggestionCategory === 'VENTILATION';
            } ) || null;
        },
        getFloorImprovement(): ImprovementSuggestion | null {
            return this.improvementSuggestions.find(e => {
                if (!e) {
                    return false;
                }
                return e.improvementSuggestionCategory === 'FLOOR';
            } ) || null;
        },
        getBasementImprovement(): ImprovementSuggestion | null {
            return this.improvementSuggestions.find(e => {
                if (!e) {
                    return false;
                }
                return e.improvementSuggestionCategory === 'BASEMENT';
            }) || null;
        },
        getNewEstimation(): DetailedEstimation | null {
            return this.newEstimation;
        }
    }
});
